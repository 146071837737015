import React from "react"

import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useIntl } from "react-intl"

import LoginRequired from "./login_required"

const WalletNavigation = ({ location }) => {
  const page = location.pathname.split("/").pop()
  return (
    <ul className="nav nav-pills mb-2">
      <NavLink page={page} to="wallet" title="Welcome" />
      <NavLink page={page} to="wallet_balances" title="Balances" login={true} />
      <NavLink page={page} to="wallet_send" title="Send" login={true} />
      <NavLink page={page} to="wallet_receive" title="Receive" login={true} />
      <NavLink
        page={page}
        to="wallet_transactions"
        title="Transactions"
        login={true}
      />
    </ul>
  )
}

const NavLink = ({ page, to, title, login }) => {
  const intl = useIntl()
  const titleTranslated = intl.formatMessage({ id: title })
  const span = <span>{titleTranslated}</span>
  const loginRequired = <LoginRequired>{titleTranslated}</LoginRequired>
  return (
    <Link
      to={"/" + to}
      className={"nav-link " + (page == to ? "active" : "") + " mr-1 mb-1"}
    >
      {!login ? span : loginRequired}
    </Link>
  )
}

export default WalletNavigation
