import React, { Component, Fragment } from "react"

import { withAuth0 } from "@auth0/auth0-react"
import Alert from "react-bootstrap/Alert"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FormattedMessage } from "react-intl"
import { injectIntl } from "react-intl"
import ReactMarkdown from "react-markdown"

import Error from "./error"
import Space from "./space"

class WalletSend extends Component {
  constructor(props) {
    super(props)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleClearAll = this.handleClearAll.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSend = this.handleSend.bind(this)
    this.handleVerify = this.handleVerify.bind(this)
    this.updateFee = this.updateFee.bind(this)
    this.state = {
      status: "send",
      alert: null,
      id: null,
      currency: "BTC",
      address: "",
      amount: 0,
      fee: 0,
      verificationCode: "",
    }
  }

  handleCancel(e) {
    e.preventDefault()
    this.setState({
      status: "send",
      alert: null,
      id: null,
      currency: "BTC",
      address: "",
      amount: 0,
      fee: 0,
      verificationCode: "",
    })
  }

  handleClearAll(e) {
    e.preventDefault()
    this.setState({
      status: "send",
      alert: null,
      id: null,
      currency: "BTC",
      address: "",
      amount: 0,
      fee: 0,
      verificationCode: "",
    })
  }

  handleInputChange(e) {
    const target = e.target
    const name = target.name
    const value = target.type === "checkbox" ? target.checked : target.value

    this.setState({ [name]: value }, () => {
      if (
        this.state.status == "send" &&
        (name == "currency" || name == "address") &&
        this.state.currency &&
        this.state.address
      ) {
        this.updateFee()
      }
    })
  }

  handleSend(e) {
    e.preventDefault()
    this.setState(
      {
        status: "sending",
        alert: null,
      },
      () => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL("/wallet/send", process.env.GATSBY_API_URL)
            const params = {}
            params.currency = this.state.currency
            params.address = this.state.address
            params.amount = this.state.amount
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "POST",
              headers: { Authorization: `Bearer ${accessToken}` },
            })
              .then(response => {
                if (response.ok) {
                  response.json().then(json => {
                    this.setState({
                      status: "verify",
                      id: json.id,
                      fee: json.fee,
                    })
                  })
                } else {
                  response.text().then(text => {
                    this.setState({
                      status: "send",
                      alert: text,
                    })
                  })
                }
              })
              .catch(error => {
                this.setState({
                  status: "send",
                  alert: error.message,
                })
              })
          })
      }
    )
  }

  handleVerify(e) {
    e.preventDefault()
    this.setState(
      {
        status: "verifying",
        alert: null,
      },
      () => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL("/wallet/verify", process.env.GATSBY_API_URL)
            const params = {}
            params.id = this.state.id
            params.verificationCode = this.state.verificationCode
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "POST",
              headers: { Authorization: `Bearer ${accessToken}` },
            })
              .then(response => {
                if (response.ok) {
                  response.text().then(text => {
                    this.setState({
                      status: "sent",
                    })
                  })
                } else {
                  response.text().then(text => {
                    this.setState({
                      status: "verify",
                      alert: text,
                    })
                  })
                }
              })
              .catch(error => {
                this.setState({
                  status: "verify",
                  alert: error.message,
                })
              })
          })
      }
    )
  }

  updateFee() {
    this.setState(
      {
        alert: null,
      },
      () => {
        this.props.auth0
          .getAccessTokenSilently({
            audience: process.env.GATSBY_AUTH0_AUDIENCE,
            scope: process.env.GATSBY_AUTH0_SCOPE,
          })
          .then(accessToken => {
            const url = new URL(
              "/wallet/estimateFee",
              process.env.GATSBY_API_URL
            )
            const params = {}
            params.currency = this.state.currency
            params.address = this.state.address
            Object.keys(params).forEach(key =>
              url.searchParams.append(key, params[key])
            )
            fetch(url, {
              method: "GET",
              headers: { Authorization: `Bearer ${accessToken}` },
            })
              .then(response => {
                if (response.ok) {
                  response.text().then(text => {
                    this.setState({
                      fee: text,
                    })
                  })
                } else {
                  response.text().then(text => {
                    this.setState({
                      alert: text,
                    })
                  })
                }
              })
              .catch(error => {
                this.setState({
                  alert: error.message,
                })
              })
          })
      }
    )
  }

  render() {
    const { intl } = this.props
    return (
      <Row>
        <Col className="col-auto">
          <Card className="border-dark mb-2" style={{ width: "27rem" }}>
            <Card.Header className="text-white bg-dark">
              <FormattedMessage id={"Send"} />
            </Card.Header>
            <Card.Body>
              {this.state.status == "verify" && (
                <Alert variant="info">
                  <FormattedMessage id={"Send.verify"} />
                </Alert>
              )}
              {this.state.status == "sent" && (
                <Alert variant="success">
                  <FormattedMessage id={"Send.sent"} />
                </Alert>
              )}
              {this.state.alert && (
                <Alert variant="danger">
                  <FormattedMessage id={this.state.alert} />
                </Alert>
              )}
              <Form autoComplete="off">
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id={"Currency"} />
                  </Form.Label>
                  <Form.Control
                    name="currency"
                    as="select"
                    value={this.state.currency}
                    disabled={this.state.status != "send"}
                    onChange={this.handleInputChange}
                  >
                    <option>BTC</option>
                    <option>ETH</option>
                    <option>XRP</option>
                    <option disabled>BCH</option>
                    <option>LTC</option>
                    <option>DOGE</option>
                    <option disabled>BTG</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id={"Address"} />
                  </Form.Label>
                  <Form.Control
                    name="address"
                    type="text"
                    value={this.state.address}
                    disabled={this.state.status != "send"}
                    onChange={this.handleInputChange}
                    autoComplete="chrome"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id={"Amount"} />
                  </Form.Label>
                  <Form.Control
                    name="amount"
                    type="number"
                    value={this.state.amount}
                    disabled={this.state.status != "send"}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id={"Fee"} />
                  </Form.Label>
                  <Form.Control
                    name="fee"
                    type="number"
                    value={this.state.fee}
                    disabled
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    <FormattedMessage id={"Verification Code"} />
                  </Form.Label>
                  <Form.Control
                    name="verificationCode"
                    type="text"
                    value={this.state.verificationCode}
                    disabled={this.state.status != "verify"}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
                {this.state.status == "send" && (
                  <>
                    <Button variant="primary" onClick={this.handleSend}>
                      <FormattedMessage id={"Send"} />
                    </Button>
                    <Space />
                    <Button variant="secondary" onClick={this.handleClearAll}>
                      <FormattedMessage id={"Clear All"} />
                    </Button>
                  </>
                )}
                {this.state.status == "sending" && (
                  <>
                    <Button variant="primary" disabled>
                      <FormattedMessage id={"Send"} />
                    </Button>
                    <Space />
                    <Button variant="secondary" disabled>
                      <FormattedMessage id={"Clear All"} />
                    </Button>
                  </>
                )}
                {this.state.status == "verify" && (
                  <>
                    <Button variant="danger" onClick={this.handleVerify}>
                      <FormattedMessage id={"Send"} />
                    </Button>
                    <Space />
                    <Button variant="secondary" onClick={this.handleCancel}>
                      <FormattedMessage id={"Cancel"} />
                    </Button>
                  </>
                )}
                {this.state.status == "verifying" && (
                  <>
                    <Button variant="danger" disabled>
                      <FormattedMessage id={"Send"} />
                    </Button>
                    <Space />
                    <Button variant="secondary" disabled>
                      <FormattedMessage id={"Cancel"} />
                    </Button>
                  </>
                )}
                {this.state.status == "sent" && (
                  <>
                    <Button variant="danger" disabled>
                      <FormattedMessage id={"Send"} />
                    </Button>
                    <Space />
                    <Button variant="secondary" disabled>
                      <FormattedMessage id={"Cancel"} />
                    </Button>
                  </>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col className="col-auto">
          <Alert variant="secondary" style={{ width: "18rem" }}>
            <ReactMarkdown
              children={intl.formatMessage({ id: "Wallet.Send" })}
            />
          </Alert>
        </Col>
      </Row>
    )
  }
}

export default withAuth0(injectIntl(WalletSend))
